// Adjustment factors for seasonal payment plan
export const CANCELLATION_PERIOD = 14 // days
export const SEASONAL_ADJUSTMENT_FACTOR = 0.25
export const MINIMUM_ANNUAL_CONSUMPTION = 3100
export const SEASONAL_SUMMER_START_MONTH = 4
export const SEASONAL_WINTER_START_MONTH = 10
export const SEASONAL_ADJUSTMENT_WINTER = 1 + SEASONAL_ADJUSTMENT_FACTOR
export const SEASONAL_ADJUSTMENT_SUMMER = 1 - SEASONAL_ADJUSTMENT_FACTOR
export const SO_FLEX_GREEN_TARIFF_CODE = "SGRN"
export const PAYMENT_SCHEDULE_MONTH_LAST_DAY = 28
export const MINIMUM_CREDIT_TO_RENEW_WITH_CREDIT_IN_POUNDS = 12
export const LAST_READ_THRESHOLD_TO_RENEW_WITH_CREDIT_IN_DAYS = 90
export const LAST_BILL_CREATED_THRESHOLD_TO_RENEW_WITH_CREDIT_IN_DAYS = 61
export const TIMES_PAYED_TO_RENEW_WITH_CREDIT_IN_MONTHS = 3
export const DAYS_IN_YEAR = 365

export const RateName = {
  Standard: "Standard",
  Night: "Night",
  Day: "Day",
} as const

export const MPXN = {
  MPRN: "MPRN",
  MPAN: "MPAN",
} as const

export const FuelType = {
  ELEC1: "elec1",
  ELEC2: "elec2",
  GAS: "gas",
} as const

export const Fuel = {
  Electricity: "Electricity",
  GasAndElectricity: "Gas & Electricity",
  Gas: "Gas",
} as const

export const Meter = {
  Smart: "Smart Meter",
  Standard: "Standard Meter",
  SmartAndStandard: "Smart & Standard",
} as const

export const EnergyType = {
  Electricity: "electricity",
  Gas: "gas",
} as const

export const PaymentMethodDefaultStatus = {
  Pending: "Pending",
  Default: "Default",
  NotDefault: "NotDefault",
} as const

export const PaymentMethodsStatus = {
  Pending: "Pending",
  Active: "Active",
  Cancelled: "Cancelled",
  Failed: "Failed",
  RequestingActivation: "RequestingActivation",
  PendingCancellation: "PendingCancellation",
  RequestingCancellation: "RequestingCancellation",
  FailedCancellation: "FailedCancellation",
  Paused: "Paused",
  CoolingOff: "CoolingOff",
} as const

export const knownMeterErrorCodes = [
  {
    pattern: /Agreement from date should not be null/i,
    description: "Agreement from date should not be null",
    groupName: "agreement-from-date-null",
  },
  {
    pattern: /Not all meterpoints accepting reads/i,
    description: "Not all meterpoints accepting reads",
    groupName: "not-all-meterpoint-accepting-reads",
  },
  {
    pattern: /is after the end of the first reading window of/i,
    description:
      "Reading [DATE] is after the end of the first reading window of [METER]",
    groupName: "first-reading-window-closed",
  },
  {
    pattern:
      /As meter technical details have arrived, and the meter is not missing EACs on the reading date, please use Submit meter readings/i,
    description:
      "As meter technical details have arrived, and the meter is not missing EACs on the reading date, please use Submit meter readings",
    groupName: "technical-details-already-exist",
  },
  {
    pattern: /The meter .* does not have EACs for all its registers at date/i,
    description:
      "The meter [METER] does not have EACs for all its registers at date/time [DATE]",
    groupName: "no-eac",
  },
  {
    pattern: /DEADLINE_EXCEEDED.*be-identity-headless.*/i,
    description:
      "DEADLINE_EXCEEDED: deadline exceeded after [TIME]s. [closed=[], open=[[remote_addr=be-identity-headless/.:]]]",
    groupName: "timeout-be-identity",
  },
  {
    pattern: /DEADLINE_EXCEEDED.*be-customers-headless.*/i,
    description:
      "DEADLINE_EXCEEDED: deadline exceeded after [TIME]s. [closed=[], open=[[remote_addr=be-customers-headless/.:]]]",
    groupName: "timeout-be-customers",
  },
  {
    pattern: /DEADLINE_EXCEEDED.*be-assets-headless.*/i,
    description:
      "DEADLINE_EXCEEDED: deadline exceeded after [TIME]s. [closed=[], open=[[remote_addr=be-assets-headless/.:]]]",
    groupName: "timeout-be-assets",
  },
  {
    pattern: /UNAVAILABLE: io exception/i,
    description: "UNAVAILABLE: io exception",
    groupName: "io-exception",
  },
  {
    pattern: /UNAVAILABLE: Connection closed after GOAWAY/i,
    description:
      "UNAVAILABLE: Connection closed after GOAWAY. HTTP/2 error code: NO_ERROR, debug data: app_requested",
    groupName: "connection-closed",
  },
  {
    pattern: /UNKNOWN: An error occurred/i,
    description: "UNKNOWN: An error occurred",
    groupName: "server-unknown-error",
  },
  {
    pattern:
      /No coefficient found for date .* and unable to find a suitable estimate in profile curve/i,
    description:
      "No coefficient found for date [DATE] and unable to find a suitable estimate in profile curve [ID]",
    groupName: "no-coefficient",
  },
  {
    pattern: /There are no calorific values for pricing area/i,
    description:
      "There are no calorific values for pricing area [AREA] between [DATES]",
    groupName: "no-calorific-values",
  },
  {
    pattern: /The meter .* does not have a register with identifier/i,
    description:
      "The meter [METER] does not have a register with identifier [ID]",
    groupName: "meter-has-no-register",
  },
  {
    pattern: /The meterpoint .* does not have an EUC at date/i,
    description:
      "The meterpoint [METER] does not have an EUC at date/time [DATE]",
    groupName: "no-euc",
  },
  {
    pattern: /The meter .* is not attached to the meter point at date/i,
    description:
      "The meter [METER] is not attached to the meter point at date/time [DATE]",
    groupName: "meter-not-attached",
  },
  {
    pattern: /Meterpoint with id .* is not accepting reads/,
    description: "Meterpoint with id [id] is not accepting reads",
    groupName: "meterpoint-not-accepting-reads",
  },
] as const

export const ConsentMeterType = {
  MPAN: "ELEC",
  MPRN: "GAS",
} as const
