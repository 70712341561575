<template>
  <div v-if="bannerContent.length">
    <TwoColumnActionBanner
      v-if="displayFullScreenBanner && isTwoColumnBanner(fullScreenBanner)"
      :overview-title="fullScreenBanner.overviewTitle"
      :overview-content="fullScreenBanner.overviewContent"
      :overview-background-color="fullScreenBanner.overviewBackgroundColor"
      :overview-background-image="fullScreenBanner.overviewBackgroundImage"
      :checkbox-content="fullScreenBanner.checkboxContent"
      :action-disclaimer="fullScreenBanner.actionDisclaimer"
      :action-content="fullScreenBanner.actionContent"
      :action-button-label="fullScreenBanner.actionButtonLabel"
      :action-error-message="fullScreenBanner.actionErrorMessage"
      :action="fullScreenBanner.action"
      :checkbox-error-message="fullScreenBanner.checkboxErrorMessage"
      :banner-id="fullScreenBanner.banner_id"
      @close="closeBanner(fullScreenBanner)"
    />
    <Banner
      v-else-if="displayFullScreenBanner"
      class="notification-banner"
      :full-screen-style="sectionStyle(displayFullScreenBanner)"
      is-full-screen
      :hide-close-icon="false"
      :show-close-button="displayFullScreenBanner.showCloseButton"
      :close-button-props="showFullScreenCloseButton"
      @handle-close-banner="exitBanner(displayFullScreenBanner)"
    >
      <template #content>
        <Article
          :class="{
            'notification-banner__fullscreen-content':
              displayFullScreenBanner.showCloseButton,
          }"
          :text-content="displayFullScreenBanner.fullScreenTextarea"
          :variable-set="variableSet"
          no-margin
        />
        <div class="notification-banner__button-wrapper">
          <template v-if="displayFullScreenBanner.button.length">
            <NavButton
              v-for="(button, index) in displayFullScreenBanner.button"
              v-bind="cmsBlockToProps(button)"
              :key="`button-${index}`"
              class="notification-banner__button"
              @click="closeBanner(displayFullScreenBanner)"
            />
          </template>
          <AppButton
            v-if="
              !Object.keys(showFullScreenCloseButton).length &&
              !displayFullScreenBanner.hideFullScreenCloseButton
            "
            class="notification-banner__button"
            variant="secondary"
            @click="closeBanner(displayFullScreenBanner)"
          >
            Close
          </AppButton>
        </div>
        <Article
          v-if="
            displayFullScreenBanner.fullScreenBottomTextarea &&
            displayFullScreenBanner.fullScreenBottomTextarea.length
          "
          class="notification-banner__bottom-text"
          :text-content="
            displayFullScreenBanner.fullScreenBottomTextarea[0].textContent
          "
          @link-clicked="closeBanner(displayFullScreenBanner)"
        />
      </template>
    </Banner>

    <div class="notification-banner__top-container">
      <div v-for="(banner, index) in orderedActiveBanners" :key="index">
        <Banner
          class="notification-banner"
          :hide-close-icon="banner.hideCloseIcon"
          @handle-close-banner="closeBanner(banner)"
        >
          <template #content>
            <Article
              :text-content="banner.bannerText"
              :variable-set="variableSet"
              no-margin
              @go-to-renew="goToRenew(banner)"
            />
          </template>
        </Banner>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@soenergy/frontend-library/src/components/Banner"
import Article from "@soenergy/frontend-library/src/components/Article"
import NavButton from "@soenergy/frontend-library/src/components/NavButton"
import AppButton from "@soenergy/frontend-library/src/components/AppButton"
import TwoColumnActionBanner from "@/components/TwoColumnActionBanner.vue"
import MixpanelService from "@soenergy/frontend-library/src/services/Mixpanel"
import {
  getDomain,
  setCookie,
  getCookie,
} from "@soenergy/frontend-library/src/services/Cookies.js"
import preventScrollMixin from "@soenergy/frontend-library/src/mixins/preventScrollMixin"

const scopeCookieToAccount = (cookieName, accountId) =>
  `${cookieName}_${accountId}`

export default {
  components: {
    Banner,
    Article,
    NavButton,
    AppButton,
    TwoColumnActionBanner,
  },
  mixins: [preventScrollMixin],
  props: {
    bannerContent: {
      type: Array,
      default: () => [],
    },
    variableSet: {
      type: Object,
      default: () => {
        return {}
      },
    },
    accountId: {
      type: Number,
      default: null,
    },
    accountOpen: {
      type: Boolean,
      default: true,
    },
    fullscreenBannerDismissed: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["fullscreen-banner-dismissed"],
  data() {
    return {
      dismissedBanners: {},
      fullscreenBannerActive: {},
      fullScreenBanner: null,
    }
  },
  computed: {
    orderedBanners() {
      let result = [...this.bannerContent].sort(
        (a, b) => a.importance - b.importance
      )
      return result.reverse()
    },
    orderedActiveBanners() {
      return this.orderedBanners.filter(
        (banner) =>
          !this.dismissedBanners[banner.cookieName] &&
          !this.isBannerFullScreen(banner)
      )
    },
    comingFormNexusHomepage() {
      return (
        process.env.VUE_APP_USE_NEXUS_HOME_PAGE === "true" &&
        document.referrer === `${window.location.origin}/myaccount/`
      )
    },
    displayFullScreenBanner() {
      return this.accountOpen &&
        this.fullScreenBanner &&
        this.fullScreenBanner.cookieName &&
        !this.fullscreenBannerDismissed &&
        !this.comingFormNexusHomepage
        ? this.fullScreenBanner
        : null
    },
    showFullScreenCloseButton() {
      return this.displayFullScreenBanner.showCloseButton &&
        this.displayFullScreenBanner.closeButton &&
        this.displayFullScreenBanner.closeButton.length
        ? this.displayFullScreenBanner.closeButton[0]
        : {}
    },
  },
  watch: {
    displayFullScreenBanner: {
      handler(displayFullScreen) {
        this.preventBodyScroll(displayFullScreen)
      },
    },
    bannerContent: {
      handler() {
        this.loadDismissalStates()
        this.findFullScreenBanner()
      },
      deep: true,
    },
  },
  created() {
    this.loadDismissalStates()
  },
  mounted() {
    this.findFullScreenBanner()
  },
  methods: {
    isTwoColumnBanner(banner) {
      return banner.component === "AccountTwoColumnActionBanner"
    },
    isBannerFullScreen(banner) {
      return banner.fullScreenBanner || this.isTwoColumnBanner(banner)
    },
    sectionStyle(banner) {
      const style = {}

      if (
        (this.isBannerFullScreen(banner) && banner.fontColor) ||
        (this.isBannerFullScreen(banner) && banner.backgroundColor)
      ) {
        banner.fontColor ? (style.color = banner.fontColor) : null
        banner.backgroundColor
          ? (style.backgroundColor =
              process.env.VUE_APP_NEW_BRANDING === "true" &&
              banner.backgroundColorNew
                ? banner.backgroundColorNew
                : banner.backgroundColor)
          : null
      }
      return style
    },
    cmsBlockToProps: (props) => props,
    closeBanner(banner) {
      this.setBannerCookie(banner)
      this.loadDismissalStates()
      this.$emit("fullscreen-banner-dismissed")
    },
    goToRenew(banner) {
      this.closeBanner(banner)
      this.$router.push("/renew")
      MixpanelService.sendEvent("Click - Renewal Banner")
    },
    loadDismissalStates() {
      this.bannerContent.forEach((banner) => {
        const wasDismissed = Boolean(this.getBannerCookie(banner))
        this.displayFullScreenBanner &&
        this.displayFullScreenBanner.cookieName === banner.cookieName
          ? (this.fullscreenBannerActive[banner.cookieName] = wasDismissed)
          : (this.dismissedBanners[banner.cookieName] = wasDismissed)

        if (
          wasDismissed &&
          this.displayFullScreenBanner &&
          this.displayFullScreenBanner.cookieName === banner.cookieName
        ) {
          this.fullScreenBanner = null
        }
      })
    },
    findFullScreenBanner() {
      const highestPriorityActiveBanner = this.orderedBanners
        .filter(
          (banner) =>
            !this.fullscreenBannerActive[banner.cookieName] &&
            this.isBannerFullScreen(banner) &&
            !this.getBannerCookie(banner)
        )
        .sort((banner1, banner2) => banner2.importance - banner1.importance)[0]

      if (highestPriorityActiveBanner) {
        this.fullScreenBanner = highestPriorityActiveBanner
      }
    },
    setBannerCookie(banner) {
      let name = scopeCookieToAccount(banner.cookieName, this.accountId)
      let value = banner.cookieValue || "true"
      let days = banner.cookieDuration
      let date, expires

      if (days) {
        date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = date
      } else {
        expires = ""
      }
      setCookie({
        name,
        value,
        domain: getDomain(),
        expiryDate: expires,
      })
    },
    getBannerCookie(banner) {
      if (banner && banner.cookieName) {
        const name = scopeCookieToAccount(banner.cookieName, this.accountId)
        const fetchCookie = getCookie(name)
        return fetchCookie
      }
    },
    exitBanner(banner) {
      MixpanelService.sendEvent(`Close Banner - ${banner.banner_id}`)
      this.closeBanner(banner)
    },
  },
}
</script>

<style lang="scss" scoped>
.notification-banner {
  border-bottom: 1px solid $night-light;

  &__fullscreen-content {
    margin-bottom: $spacing-9;

    @include lg {
      margin-bottom: $spacing-8;
    }
  }

  &__text {
    @include lg {
      padding: $spacing-4 0;
    }
  }

  &__button-wrapper {
    margin-top: $spacing-7;
    flex-direction: column;
    margin-bottom: $spacing-3;

    @include lg {
      margin-top: 3.5rem;
      flex-direction: row;
      margin-right: $spacing-3;

      &:not(:first-of-type) {
        margin-top: $spacing-7;
      }
    }
  }

  &__button {
    width: 100%;

    &:not(:first-child) {
      margin-top: $spacing-4;
    }

    &:focus:not(:active) {
      box-shadow: 0 0 0 0.188em $blue-300;
    }

    @include lg {
      width: unset;
      min-width: $spacing-11;

      &:not(:first-child) {
        margin-top: 0;
        margin-left: $spacing-6;
      }
    }
  }

  h4 {
    margin-bottom: $spacing-7;
  }

  &__top-container {
    width: 100%;
  }

  &__bottom-text {
    margin-top: $space-12;
  }
}
</style>
