import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import tariffProductHelper from "@/methods/tariffProductHelper"
import renewalStatusHelper from "@soenergy/frontend-library/src/helpers/renewalStatusHelper"
import { datesMixin } from "@/mixins/datesMixin.js"
import { FuelType } from "@/constants"
import GatewayPortal from "@/services/GatewayPortal"
import { RENEWAL_WINDOW_CONFIGURATION } from "@/variable_keys/Agreements"
import { elecCalculator, gasCalculator } from "@/methods/tariffHelper"
import {
  calcCostsForAgreements,
  fetchTariffsForAgreements,
} from "@/helpers/agreementCostHelper"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"

export const namespaced = true
const EXIT_FEE_WAIVE_PERIOD_DAYS = 49
const SO_FLEX_GREEN_TARIFF_CODE = "SGRN"
dayjs.extend(utc)
dayjs.extend(isSameOrBefore)
const getDefaultState = () => {
  return {
    renewalDays: EXIT_FEE_WAIVE_PERIOD_DAYS,
    currentAgreementsByTariff: [],
  }
}

export const state = getDefaultState()

export const mutations = {
  SET_RENEWAL_DAYS(state, renewalDays) {
    state.renewalDays = renewalDays
  },
  SET_CURRENT_AGREEMENTS_BY_TARIFF(state, currentAgreementsByTariff) {
    state.currentAgreementsByTariff = currentAgreementsByTariff
  },
}

export const getters = {
  anyAgreementInRenewal(state, getters, rootState, rootGetters) {
    return rootGetters.currentAgreements.some((agreement) =>
      agreementIsInProvidedPeriod(state.renewalDays, agreement)
    )
  },
  allInRenewalRenewed(state, getters, rootState, rootGetters) {
    return rootGetters.currentAgreements
      .filter((agreement) =>
        agreementIsInProvidedPeriod(state.renewalDays, agreement)
      )
      .every(
        (agreement) =>
          !!futureAgreement(agreement, rootGetters.futureAgreements)
      )
  },
  currentAgreements(state, getters, rootState, rootGetters) {
    return rootGetters.currentAgreements
  },
  meterpoitns(state, getters, rootState, rootGetters) {
    return rootGetters.meterpoints
  },
  allInRenewalEndDates(state, getters, rootState, rootGetters) {
    return rootGetters.currentAgreements.filter((agreement) =>
      agreementIsInProvidedPeriod(state.renewalDays, agreement)
    )
  },
  anyAgreementIsOutOfContract(state, getters, rootState, rootGetters) {
    return rootGetters.currentAgreements.some(
      (agreement) => !!agreement.products[0].variable
    )
  },
  agreements(state, getters, rootState, rootGetters) {
    return agreementReducer(
      rootGetters.currentAgreements,
      state,
      getters,
      rootState,
      rootGetters
    )
  },
  agreementsArray(state, getters) {
    return Object.values(getters.agreements)
  },
  getExitFeeForAgreement() {
    return function (agreement) {
      if (!agreement || agreement.isVariable) return 0

      let charges = agreement.fuels.map((fuel) => {
        let tariff = agreement.currentTariffs[fuel.thisTariffKey]
        if (tariff) {
          return tariff.earlyTerminationCharge
        } else {
          return 0
        }
      })

      return Math.max(...charges)
    }
  },
  getAnnualConsumptions(state, getters, rootState, rootGetters) {
    return function (agreement) {
      let consumption = {}
      if (!agreement) {
        return consumption
      }

      for (let i = 0; i < agreement.fuels.length; i++) {
        let currentFuel = agreement.fuels[i]

        if (currentFuel.type === FuelType.ELEC2) {
          consumption.electricity = currentFuel.endpoints.reduce(
            (total, endpoint) => {
              let day = rootGetters["meters/getEstimatedUsage"](
                endpoint.id,
                "Day"
              )
              let night = rootGetters["meters/getEstimatedUsage"](
                endpoint.id,
                "Night"
              )

              return {
                day: total.day + day,
                night: total.night + night,
                sum: day + night,
              }
            },
            {
              day: 0,
              night: 0,
              sum: 0,
            }
          )
        } else {
          let consumptionValue = currentFuel.endpoints.reduce(
            (total, endpoint) => {
              return (
                total + rootGetters["meters/getEstimatedUsage"](endpoint.id)
              )
            },
            0
          )

          if (currentFuel.type === FuelType.ELEC1) {
            consumption.electricity = { sum: consumptionValue }
          }

          if (currentFuel.type === FuelType.GAS) {
            consumption.gas = consumptionValue
          }
        }
      }

      return consumption
    }
  },
  getSumAnnualConsumption(state, getters) {
    return function (agreementsArray) {
      return agreementsArray.reduce(
        (overall, agreement) => {
          let consumption = getters.getAnnualConsumptions(agreement)

          if (consumption.electricity) {
            if (consumption.electricity.day && consumption.electricity.night) {
              overall.electricity.day += consumption.electricity.day
              overall.electricity.night += consumption.electricity.night
            }
            overall.electricity.sum += consumption.electricity.sum
          }
          if (consumption.gas) {
            overall.gas += consumption.gas
          }
          return overall
        },
        { gas: 0, electricity: { sum: 0 } }
      )
    }
  },
  calculateAnnualCostForAgreementForTariff(state, getters) {
    return function (agreement, thisOrAvailable) {
      if (!agreement) return {}
      const consumption = getters.getAnnualConsumptions(agreement)
      let total = {}
      let tariffs
      if (thisOrAvailable == "available") {
        tariffs = agreement.availableTariffs
      } else {
        tariffs = Object.keys(agreement.currentTariffs)
      }

      let elecFuelLength = 0
      let gasFuelLength = 0
      for (let i = 0; i < agreement.fuels.length; i++) {
        if (agreement.fuels[i].fuel === "Electricity") {
          elecFuelLength = agreement.fuels[i].endpoints.length
        } else {
          gasFuelLength = agreement.fuels[i].endpoints.length
        }
      }
      tariffs.map((item) => {
        let tariff =
          thisOrAvailable == "available" ? item : agreement.currentTariffs[item]

        if (typeof tariff.annualCost === "undefined") {
          tariff.annualCost = 0
        }

        if (
          (tariff.electricity ||
            (tariff.code && tariff.code.startsWith("E"))) &&
          consumption.electricity
        ) {
          let tariffObject = tariff.electricity ? tariff.electricity : tariff
          let duration =
            tariffObject.duration_months || tariffObject.durationMonths || 0
          let isFuture = tariffObject.future_sooc_tariff ? "-F" : ""
          let totalKey =
            tariffObject.code.split("-")[1] + "-" + duration + isFuture

          total[totalKey] = total[totalKey] ? total[totalKey] : 0
          total[totalKey] += parseInt(
            (
              elecCalculator(tariffObject, elecFuelLength, consumption) / 100
            ).toFixed(0),
            10
          )

          tariff.annualCost += parseInt(
            (
              elecCalculator(tariffObject, elecFuelLength, consumption) / 100
            ).toFixed(0),
            10
          )
        }
        if (
          tariff.gas ||
          (tariff.code && tariff.code.startsWith("G") && consumption.gas)
        ) {
          let tariffObject = tariff.gas ? tariff.gas : tariff
          let duration =
            tariffObject.duration_months || tariffObject.durationMonths || 0
          let isFuture = tariffObject.future_sooc_tariff ? "-F" : ""
          let totalKey =
            tariffObject.code.split("-")[1] + "-" + duration + isFuture
          total[totalKey] = total[totalKey] ? total[totalKey] : 0
          total[totalKey] += parseInt(
            (
              gasCalculator(tariffObject, gasFuelLength, consumption) / 100
            ).toFixed(0),
            10
          )

          tariff.annualCost += parseInt(
            (
              gasCalculator(tariffObject, gasFuelLength, consumption) / 100
            ).toFixed(0),
            10
          )
        }
      })

      return total
    }
  },
  hasOutOfSyncTariffs(state, getters, rootState, rootGetters) {
    const tariffs = rootGetters.currentAgreements.map((agreement) => {
      return agreement.toDt
    })

    return tariffs.filter((v, i) => tariffs.indexOf(v) === i).length !== 1
  },
  canRenewOnFutureTariff(state, getters, rootState, rootGetters) {
    const hasPendingRenewal = renewalStatusHelper.hasPendingRenewal(
      rootState.user,
      rootGetters["home/accountNumber"]
    )
    const futureTariffEnabled =
      rootState.help.featureData.futuretariffadjustments
    const futureTariffStartDate =
      rootState.help.featureData.future_product_start_date
    const anyFixedTariffIsEligible = getters.agreementsArray.some(
      (agreement) => {
        return (
          futureTariffStartDate &&
          agreement.toDt &&
          dayjs(agreement.toDt).isBefore(dayjs(futureTariffStartDate))
        )
      }
    )
    return (
      !hasPendingRenewal &&
      futureTariffEnabled &&
      (getters.anyAgreementIsOutOfContract || anyFixedTariffIsEligible)
    )
  },
  hasActiveAgreements(state, getters) {
    return !!(getters.agreements && Object.keys(getters.agreements).length)
  },
  eligibleForRenewal(state, getters, rootState, rootGetters) {
    const accountNumber = rootGetters["home/accountNumber"]
    // only checks if the user is logged in
    if (!accountNumber) return false

    const hasActiveAgreements = getters.hasActiveAgreements
    const agreementsArray = getters.agreementsArray
    const accountCreated = rootGetters["switchIn/accountCreated"]
    const supplyStartDates = rootGetters["home/supplyStartDates"]
    const user = rootState.user
    const isSwitchInProgress = rootGetters["switchIn/isSwitchInProgress"]
    const coolingPeriod = datesMixin.methods.inCoolingPeriod(
      accountCreated,
      datesMixin.methods.getLatestSupplyStartDate(supplyStartDates)
    )
    const checkFutureAgreements = (agreements) => {
      return agreements.some((agreement) => !agreement.hasFutureAgreement)
    }
    const futureAgreement = checkFutureAgreements(agreementsArray)
    if (agreementsArray && agreementsArray.length)
      return (
        !coolingPeriod &&
        hasActiveAgreements &&
        !renewalStatusHelper.hasPendingRenewal(user, accountNumber) &&
        !isSwitchInProgress &&
        futureAgreement
      )
  },
  hasNonDDTariff(state, getters) {
    return getters.agreementsArray.some(
      (agreement) => agreement.isVariableNonDD
    )
  },
  isOnVariableGreenTariff(state, getters) {
    return getters.agreementsArray.some(
      (agreement) => agreement.isVariableGreen
    )
  },
  allAgreementsEligibleForMoveWithUs(state, getters, rootState, rootGetters) {
    const activeAgreements = rootGetters?.agreements?.filter(isActive) ?? []
    if (!activeAgreements.length) return false
    return activeAgreements.every(isEligibleForMoveWithUs)
  },
}

export const actions = {
  async fetchCurrentAgreements({ commit, rootGetters }) {
    const agreementsByTariff = await fetchTariffsForAgreements(
      rootGetters["meters/meterpointAgreementPairs"]
    )

    const currentAgreementsDataByTariff = agreementsByTariff.map((agreements) =>
      calcCostsForAgreements(agreements, rootGetters.estimated_usage)
    )

    commit("SET_CURRENT_AGREEMENTS_BY_TARIFF", currentAgreementsDataByTariff)
  },

  fetchRenewalDays({ commit }) {
    return GatewayPortal.getConfiguration(RENEWAL_WINDOW_CONFIGURATION).then(
      ({ data }) => {
        if (!data?.configuration_value) return
        commit("SET_RENEWAL_DAYS", data.configuration_value)
      }
    )
  },
  async requestTariffsForAgreement({ dispatch }, { agreement, getFuture }) {
    let tariffPromises = []
    // get current tariffs
    tariffPromises.push(
      dispatch("tariffs/fetchTariffs", agreement, { root: true })
    )
    // get available tariffs
    let fetcherProps = {}
    if (getFuture) {
      fetcherProps = {
        agreement: agreement.futureAgreement,
        getTariffByCode: true,
      }
    } else {
      fetcherProps = {
        agreement: agreement,
      }
    }
    tariffPromises.push(
      dispatch("tariffs/fetchAvailableTariffs", fetcherProps, {
        root: true,
      })
    )
    let tariffResults = await Promise.all(tariffPromises)

    // modify agreement with tariff data
    agreement.currentTariffs = tariffResults[0]
    if (getFuture) {
      agreement.futureTariffs = tariffResults[1]
    } else if (tariffResults[1]) {
      agreement.availableTariffs = tariffResults[1]
    }

    // check if there is a future tariff within the available tariffs
    if (agreement.availableTariffs) {
      const futureTariffs = agreement.availableTariffs.filter((tariff) => {
        if (tariff.electricity) {
          return dayjs(tariff.electricity.start_date).isAfter(
            dayjs().utc(),
            "day"
          )
        } else if (tariff.gas) {
          return dayjs(tariff.gas.start_date).isAfter(dayjs().utc(), "day")
        } else {
          return dayjs(tariff.start_date).isAfter(dayjs().utc(), "day")
        }
      })
      // modify aggreement with fsdt
      agreement.hasOneFutureTariff = futureTariffs && futureTariffs.length === 1
    }
    return agreement
  },
}

const agreementIsInProvidedPeriod = (providedPeriod, agreement) => {
  return (
    !!agreement?.toDt &&
    dayjs(agreement.toDt, "YYYY-MM-DD").isBefore(
      dayjs().add(providedPeriod, "day")
    )
  )
}

const futureAgreement = (agreement, futureAgreements) => {
  return futureAgreements.find(
    (a) =>
      a.products[0].assets[0].id === agreement.products[0].assets[0].id &&
      a !== agreement &&
      a.products[0].reference !== agreement.products[0].reference
  )
}

const isVariable = (agreement) => {
  const isEndDateAbsent = !(
    agreement.toDt ||
    (agreement.contractedToDt === "null" ? null : agreement.contractedToDt)
  )
  const isOpenType = agreement.agreementType?.name
    ?.toLowerCase()
    ?.includes("open")
  const hasVariableProduct = agreement.products?.[0]?.variable

  return (
    isEndDateAbsent ||
    isOpenType ||
    hasVariableProduct ||
    isVariableGreen(agreement)
  )
}

const isVariableGreen = (agreement) =>
  agreement.products[0].reference?.includes(SO_FLEX_GREEN_TARIFF_CODE)

const isActive = (agreement) => {
  if (agreement.cancelled) return false

  const today = dayjs().startOf("day")
  const isEndDateAbsent = !(
    agreement.toDt ||
    (agreement.contractedToDt === "null" ? null : agreement.contractedToDt)
  )
  const contractEnd = agreement.toDt || agreement.contractedToDt
  return isEndDateAbsent || dayjs(contractEnd).isAfter(today)
}

const isEligibleForMoveWithUs = (agreement) => {
  if (isVariable(agreement)) return true

  const endDate = agreement.toDt || agreement.contractedToDt
  const today = dayjs().startOf("day")
  const contractEnd = dayjs(endDate).startOf("day")
  if (contractEnd.isSameOrBefore(today)) return false
  const daysUntilEnd = contractEnd.diff(today, "day")
  return daysUntilEnd <= EXIT_FEE_WAIVE_PERIOD_DAYS
}

const agreementReducer = (
  agreementsToReduce,
  state,
  getters,
  rootState,
  rootGetters
) => {
  return agreementsToReduce.reduce((allAgreements, currentAgreement) => {
    const prod = currentAgreement.products[0]
    const name = prod.displayName.split(" - ")[0]
    const key = `${currentAgreement.id.toString()}|${name}`
    // push future agreement through reducer so the set of props will be the same as the current
    const fa = futureAgreement(currentAgreement, rootGetters.futureAgreements)
    let faKey = null
    let futureAgreemeentObject = null
    if (fa) {
      let faAgreement = agreementReducer(
        [fa],
        state,
        getters,
        rootState,
        rootGetters
      )
      futureAgreemeentObject = faAgreement[Object.keys(faAgreement)[0]]
      faKey = `${futureAgreemeentObject.toDt || ""}|${
        futureAgreemeentObject.name
      }`
    }

    const type = tariffProductHelper.getFuelType(prod.reference)
    const gspGroup = rootGetters.meterpoints[prod.assets[0].id].ukGspGroup

    let connectedAgreement = null

    for (const agreementName in allAgreements) {
      let item = allAgreements[agreementName]
      if (
        item.name === name && // its the same tariff
        item.fuels.length === 1 && // has a exactly 1 fuel already
        item.fuels[0].type !== type && // not the same type of fuel
        (item.fuels[0].type === "gas" || type === "gas") && // one of them is gas
        item.toDt === currentAgreement.toDt // it has the same end date
      ) {
        connectedAgreement = item
      }
    }

    if (connectedAgreement) {
      connectedAgreement.fuels.push({
        type: type,
        isTwoRate: type === FuelType.ELEC2,
        productReference: prod.reference,
        fuel: prod.internalName.startsWith("Gas") ? "Gas" : "Electricity",
        thisTariffKey: `${gspGroup}|${prod.reference}`,
        availableTariffKey: `${gspGroup}|${type}|available`,
        endpoints: [...prod.assets],
      })
      // merge future agreements too(so it has 2 fuels inside future)
      if (fa) {
        if (connectedAgreement.hasFutureAgreement) {
          connectedAgreement.futureAgreement.fuels.push(
            futureAgreemeentObject.fuels[0]
          )
        } else {
          connectedAgreement.futureAgreement = futureAgreemeentObject
        }
      }
    } else {
      const tariffCode = prod.reference?.split("-")[1]
      allAgreements[key] = {
        name: name,
        isVariableGreen: tariffCode === SO_FLEX_GREEN_TARIFF_CODE,
        isFixedAndDueForRenewal: agreementIsInProvidedPeriod(
          state.renewalDays,
          currentAgreement
        ),
        isEligibleForMoveWithUs: isEligibleForMoveWithUs(currentAgreement),
        eligibleForRenewal:
          (agreementIsInProvidedPeriod(state.renewalDays, currentAgreement) ||
            prod.variable) &&
          !tariffProductHelper.isExport(prod.reference) &&
          !futureAgreemeentObject,
        hasFutureAgreement: !!futureAgreemeentObject,
        futureAgreement: futureAgreemeentObject,
        futureAgreementKey: faKey,
        isExportAgreement: tariffProductHelper.isExport(prod.reference),
        gspGroup: gspGroup,
        fromDt: currentAgreement.fromDt,
        toDt: currentAgreement.toDt,
        contractedToDt: currentAgreement.contractedToDt,
        type: currentAgreement.agreementType.name,
        displayToDt: !currentAgreement.toDt
          ? ""
          : dayjs(currentAgreement.toDt).subtract(1, "day"),
        fuels: [
          {
            type: type,
            isTwoRate: type === FuelType.ELEC2,
            productReference: prod.reference,
            fuel: prod.internalName.startsWith("Gas") ? "Gas" : "Electricity",
            thisTariffKey: `${gspGroup}|${prod.reference}`,
            availableTariffKey: `${gspGroup}|${type}|available`,
            endpoints: [...prod.assets],
          },
        ],
        agreementId: currentAgreement.id.toString(),
        isVariable: prod.variable,
        isVariableNonDD: tariffProductHelper.isVariableNonDD(prod),
      }
    }
    return allAgreements
  }, {})
}
