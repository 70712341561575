import dayjs from "dayjs"

class TariffQuote {
  constructor(rawQuote) {
    // Parsing BE response
    this.fuelType = rawQuote.fuel_type
    this.paymentMethod =
      rawQuote.payment === "Monthly Direct Debit"
        ? "Direct Debit"
        : rawQuote.payment
    this.tariffName = rawQuote.short_tariff_name.split("/")[0]
    this.durationMonths = rawQuote.tariff_length
    this.startDate = dayjs(rawQuote.supply_start_date)
    this.seasonalAdjustmentFactor = rawQuote.seasonal_adjustment_factor / 100

    this.annualElecDayUsage = rawQuote.elec_daytime_kwh_per_year
    this.annualElecNightUsage = rawQuote.elec_nighttime_kwh_per_year
    this.annualGasUsage = rawQuote.gas_kwh_per_year

    this.monthlyCostValue = rawQuote.pounds_cost_per_month
    this.annualCostValue = rawQuote.pounds_cost_per_year
    this.annualSavings = rawQuote.pounds_saving_per_year
    this.exitFee = rawQuote.early_exit_fee_per_fuel

    this.elecUnitRateStandard = rawQuote.electricity_pence_per_kw
    this.elecUnitRateDay = rawQuote.electricity_day_pence_per_kw
    this.elecUnitRateNight = rawQuote.electricity_night_pence_per_kw
    this.gasUnitRate = rawQuote.gas_pence_per_kw
    this.elecStandingCharge = rawQuote.electricity_standing_charge_pence_per_day
    this.gasStandingCharge = rawQuote.gas_standing_charge_pence_per_day

    this.elecProductCode = rawQuote.electricity_product_code
    this.gasProductCode = rawQuote.gas_product_code
    this.isRecommended = rawQuote.is_recommended
    this.id = String(rawQuote.id)
  }

  get isVariable() {
    return !this.durationMonths
  }

  monthlyCostSummer() {
    return this.getAdjustedMonthlyCost(1 - this.seasonalAdjustmentFactor)
  }

  monthlyCostWinter() {
    return this.getAdjustedMonthlyCost(1 + this.seasonalAdjustmentFactor)
  }

  monthlyCost() {
    return this.monthlyCostValue
  }

  annualCostAdjusted() {
    return this.annualCostValue
  }

  get tariffDurationFormatted() {
    if (this.isVariable) return "Variable Tariff"

    if (this.durationMonths % 12 === 0) {
      const durationInYears = ["One", "Two", "Three", "Four", "Five", "Six"][
        Math.trunc(this.durationMonths / 12) - 1
      ]
      return `${durationInYears} Year Tariff`
    }
    return `${this.durationMonths} Month Tariff`
  }

  get endDate() {
    return this.durationMonths
      ? dayjs(this.startDate).add(this.durationMonths, "months")
      : null
  }

  getAdjustedMonthlyCost(adjustmentFactor) {
    const price = this.monthlyCost() * adjustmentFactor
    return price % 1 !== 0 ? price.toFixed(2) : String(price)
  }
}

export default TariffQuote
