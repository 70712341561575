import { RouteLocationNormalized } from "vue-router"

export default (to: RouteLocationNormalized): boolean | undefined => {
  const isNexusHomeEnabled = process.env.VUE_APP_USE_NEXUS_HOME_PAGE === "true"
  if (!isNexusHomeEnabled) return

  if (to.name === "account-home") {
    let homeRedirectUrl = new URL(window.location.href)

    // Nexus home page is not served from localhost address
    const isDevelopment = process.env.NODE_ENV === "development"
    if (isDevelopment) {
      const queryString = window.location.search || ""
      const myaccountHostUrl = process.env.VUE_APP_ACCOUNT_APP_URL
      homeRedirectUrl = new URL(`${myaccountHostUrl}${queryString}`)
    }

    homeRedirectUrl.pathname = "/myaccount"
    window.location.assign(homeRedirectUrl.toString())
    return false
  }
}
