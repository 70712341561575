export const namespaced = true

import dayjs from "dayjs"
import localeData from "dayjs/plugin/localeData"
dayjs.extend(localeData)

const getDefaultState = () => {
  return {
    currentPsr: [], // currently set against the account
    vulnerabilities: [], // available option list
    currentStepNumber: 0,
    steps: [],
    defaultSteps: [
      {
        name: "PsrHome",
        formData: [],
        pageOptions: [],
        trackingName: "PSR - Here to Help",
      },
      {
        name: "YourHousehold",
        formData: [],
        pageOptions: [9, 26, 31, 12],
        trackingName: "PSR - Your Household",
      },
      {
        name: "YourCircumstances",
        formData: [],
        pageOptions: [29, 30],
        trackingName: "PSR - Your Circumstances",
      },
      {
        name: "SightHearing",
        formData: [],
        pageOptions: [5, 1, 17, 18],
        trackingName: "PSR - Sight, Hearing and Speech",
      },
      {
        name: "PhysicalNeeds",
        formData: [],
        pageOptions: [10, 21, 2, 19],
        trackingName: "PSR - Physical Needs",
      },
      {
        name: "CognitiveNeeds",
        formData: [],
        pageOptions: [27, 14, 13],
        trackingName: "PSR - Cognitive Needs",
      },
      {
        name: "EmergencySupport",
        formData: [],
        pageOptions: [],
        trackingName: "PSR - Emergency Support (submit page)",
      },
      {
        name: "YourServices",
        formData: [],
        pageOptions: [],
        trackingName: "PSR - Your services",
      },
      {
        name: "AllDone",
        formData: [],
        pageOptions: [],
        trackingName: "PSR - Thank You",
      },
    ],
    nameMapping: {
      9: "Aged 60 or over",
      26: "Live with a child under 5 years old",
      31: "Everyone in the home is under 18",
      29: "Recently had a life-changing event e.g. job loss, bereavement, or relationship break-up",
      30: "Recently returned from a stay in hospital",
      5: "Sight impaired or partially sighted",
      1: "Severely sight impaired or blind",
      17: "Deaf or hard of hearing",
      18: "Speech issue such as stammer, apraxia or dysarthria",
      10: "Physical disability",
      21: "Long-term or serious illness",
      2: "Dependent on electronic equipment for health or mobility",
      19: "A condition that relies on water supply, showering or bathing",
      27: "Mental health condition",
      14: "Dementia or another cognitive impairment",
      13: "Developmental condition such as autism or ADHD",
      12: "Unable to communicate in English",
      28: "Additional presence preferred",
      22: "Careline/telecare system",
      6: "Heart or lung issues & assisted ventilation",
      20: "Heart or lung issues & assisted ventilation",
      15: "Medically dependent on showering or bathing",
      23: "Medicine refrigeration",
      3: "Nebuliser and apnoea monitor",
      4: "Oxygen concentrator",
      24: "Home oxygen therapy",
      16: "Poor sense of smell or taste",
      25: "Restricted hand movement",
      7: "Stair lift, hoist or electric bed",
      8: "Bath Hoist",
      11: "Other",
    },
    psrSubmissionError: "",
    failedPsr: [],
    successfulPsr: [],
    fixedLengthPsr: [29, 30],
    psrWithNote: [12],
  }
}

export const state = getDefaultState()

export const mutations = {
  SET_CURRENT_STEP_NUMBER(state, stepNumber) {
    state.currentStepNumber = stepNumber
  },
  SET_STEP_COMPLETION(state, data) {
    state.steps[data.step].completed = data.completed
  },
  SET_VULNERABILITY_OPTIONS(state, data) {
    state.vulnerabilities = data
  },
  SET_CURRENT_PSR(state, data) {
    state.currentPsr = data
  },
  UPDATE_STEPS(state, updatedSteps) {
    state.steps = updatedSteps
  },
  SET_FORM_VALUE(state, data) {
    let formObject = state.steps[data.stepNumber].formData.find(
      (item) => item.name === data.name
    )
    // set value
    if (typeof data.value !== "undefined") formObject.value = data.value
    // set disabled if applies
    if (typeof data.disabled !== "undefined")
      formObject.disabled = formObject.permanentlyDisabled
        ? true
        : data.disabled
    // set/reset errors
    formObject.errorMessage = data.errorMessage ? data.errorMessage : ""
    formObject.hasError = !!data.hasError
  },
  SET_PSR_ERROR(state, errorMessage) {
    state.psrSubmissionError = errorMessage
  },
  SET_FAILED_PSR(state, failedPsr) {
    state.failedPsr = failedPsr
  },
  SET_SUCCESSFUL_PSR(state, successfulPsr) {
    state.successfulPsr = successfulPsr
  },
}

export const getters = {
  activePsrs: (state) => {
    return state.currentPsr.filter(
      (psr) => !psr.toDt || new Date(psr.toDt) > new Date()
    )
  },
  vulnerabilities: (state) => {
    return state.vulnerabilities
  },
  getDataByName: (state) => (dataName) => {
    const data = state.steps[state.currentStepNumber].formData.find(
      (item) => item.name === dataName
    )
    return data ? data.value : null
  },
  getDataObjectByName: (state) => (dataName) => {
    const data = state.steps[state.currentStepNumber].formData.find(
      (item) => item.name === dataName
    )
    return data
  },
  getErrorByName: (state) => (dataName) => {
    const data = state.steps[state.currentStepNumber].formData.find(
      (item) => item.name === dataName
    )
    return data ? data.hasError : null
  },
  getErrorMessageByName: (state) => (dataName) => {
    const data = state.steps[state.currentStepNumber].formData.find(
      (item) => item.name === dataName
    )
    return data ? data.errorMessage : null
  },
  getAllDataByKey: (state) => {
    let allDataByKey = {}
    state.steps.map((step) => {
      step.formData.map((formItem) => {
        allDataByKey[formItem.name] = formItem.value
      })
    })
    return allDataByKey
  },
}

export const actions = {
  buildFormOptions({ commit, state }, vulnerabilityOptions) {
    // kill references so the original won't be effected
    let localSteps = JSON.parse(JSON.stringify(state.defaultSteps))

    const yearOptions = () => {
      let yearStart = dayjs().year()
      let yearEnd = dayjs().year() + 5
      let years = Array(yearEnd - yearStart + 1)
        .fill()
        .map(() => yearStart++)
      return years
    }

    // loop steps
    for (let i = 0; i < localSteps.length; i++) {
      if (localSteps[i].pageOptions.length) {
        // loop pageOptions within the steps
        for (
          let option = 0;
          option < localSteps[i].pageOptions.length;
          option++
        ) {
          let pageOptionId = localSteps[i].pageOptions[option]
          // find matching vulnerability from the API data
          let vulnerability = vulnerabilityOptions.find(
            (item) => item.id === pageOptionId
          )
          // find out if its an existing PSR
          const isExistingPsr = state.currentPsr.find(
            (item) => item.description === vulnerability.name
          )

          const hasSubOption =
            !state.fixedLengthPsr.includes(vulnerability.id) &&
            vulnerability.expirable
          const hasNotes = state.psrWithNote.includes(vulnerability.id)

          localSteps[i].formData.push({
            name: localSteps[i].name + "_" + vulnerability.id,
            juniferName: vulnerability.name,
            displayName: state.nameMapping[vulnerability.id],
            id: vulnerability.id,
            value: !!isExistingPsr,
            dirty: false,
            hasSubOption: hasSubOption,
            hasNotes: hasNotes,
            errorMessage: "",
            hasError: false,
            disabled: !!isExistingPsr,
            permanentlyDisabled: !!isExistingPsr,
          })
          // add year/month selector
          if (hasSubOption) {
            localSteps[i].formData.push({
              name: localSteps[i].name + "_" + vulnerability.id + "_month",
              id: vulnerability.id,
              value: "",
              dirty: false,
              placeholder: "Month",
              options: dayjs.months(),
              isSubOption: true,
            })
            localSteps[i].formData.push({
              name: localSteps[i].name + "_" + vulnerability.id + "_year",
              id: vulnerability.id,
              value: "",
              dirty: false,
              placeholder: "Year",
              options: yearOptions(),
              isSubOption: true,
            })
          }
          if (hasNotes) {
            localSteps[i].formData.push({
              name: localSteps[i].name + "_" + vulnerability.id + "_notes",
              id: vulnerability.id,
              value: isExistingPsr ? isExistingPsr.note : "",
              dirty: false,
              placeholder: "Please type here",
              isSubOption: true,
              permanentlyDisabled: !!isExistingPsr,
            })
          }
        }
      }
    }

    commit("UPDATE_STEPS", localSteps)
  },
}
